import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import {
  ComparisonTable,
  LinkWrapper,
  SectionContainerSC as SectionContainer,
  Text,
} from '@components';
import { COMPARISON_TABLE_DATA, COMPARISON_TABLE_DATE } from '@shared/constants/comparison';
import { usePlansValuesContext } from '@context';

const Comparison = ({
  title,
  description,
  tableHighlight,
  buttonText,
  buttonLink,
  buttonProps,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    isLoading,
    suggestedPlan: { currency, pricePerMonth } = {}, // default value for cms preview to work while loading plans
  } = usePlansValuesContext();

  const tableData = COMPARISON_TABLE_DATA({ currency, pricePerMonth, t, isLoading });

  return (
    <SectionContainer data-section-id="Comparison" title={title} description={description}>
      {/* Table wrapping Box required to fix vertical stretching bug on safari when it overflows on mobile */}
      <Box>
        <ComparisonTable
          data={tableData.content}
          highlight={tableHighlight || tableData.highlight}
        />
      </Box>

      {/*{buttonLink && (*/}
      {/*  <Box display="flex" justifyContent="center" mt={16}>*/}
      {/*    <LinkWrapper link={buttonLink} withParams>*/}
      {/*      <Button width={180} {...buttonProps}>*/}
      {/*        {buttonText}*/}
      {/*      </Button>*/}
      {/*    </LinkWrapper>*/}
      {/*  </Box>*/}
      {/*)}*/}

      <Box maxWidth={880} mt={24} mx="auto" px={12}>
        <Text.Body3 color="darkBlue_60" textAlign="center" mb={16}>
          {t('common:wireGuardTrademark')}
        </Text.Body3>

        <Text.Body3 color="darkBlue_60" textAlign="center" mb={16}>
          {t('common:dateOfComparison', {
            date: COMPARISON_TABLE_DATE.toLocaleString(language, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }),
          })}
        </Text.Body3>
        <Text.Body3 color="darkBlue_60" textAlign="center">
          {t('common:atlasIsUnafilliated')}
        </Text.Body3>
      </Box>
    </SectionContainer>
  );
};

export default Comparison;
