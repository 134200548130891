import React from 'react';

import { Box, Button } from '@core';
import { LinkWrapper } from '@components';
import { PRICING_LINK } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import atlasvpnLogo from '@images/logos/atlas-logo-white-text.svg';
import expressVpnLogo from '@images/comparison/express-vpn.svg';
import cyberGhostLogo from '@images/comparison/cyber-ghost.svg';
import hotspotShieldLogo from '@images/comparison/hotspot-shield.svg';
import protonVpnLogo from '@images/comparison/proton-vpn.svg';

import { GA4_EVENTS } from './google';

export const COMPARISON_TABLE_DATE = new Date(Date.UTC(2023, 6, 19)); // 2023-07-19

export const ComparisonHighlightLabel = ({ text }) => (
  <Box px={16}>
    <LinkWrapper link={PRICING_LINK} withParams>
      <Button
        type="button"
        color="primary"
        size="small"
        fullWidth
        customEvent={{ eventName: GA4_EVENTS.comparisonTableCtaClick }}
      >
        {text}
      </Button>
    </LinkWrapper>
  </Box>
);

const vpnProviderPrices = {
  cyberGhost: {
    USD: 2.19,
    GBP: 1.92,
    EUR: 2.19,
    AUD: 3.27,
    CAD: 2.89,
    JPY: 320,
    CNY: 15.8,
    PLN: 8.68,
    NOK: 22.03,
    KRW: 2768,
    AED: 8.04,
    IDR: 33022,
  },
  expressVpn: {
    USD: 6.67,
    GBP: 5.25,
    EUR: 5.94,
    AUD: 9.82,
    CAD: 8.79,
    JPY: 929,
    CNY: 48.09,
    PLN: 26.43,
    NOK: 67.1,
    KRW: 8433,
    AED: 24.5,
    IDR: 100575,
  },
  hotSpotShield: {
    USD: 7.99,
    GBP: 6.13,
    EUR: 7.11,
    AUD: 11.76,
    CAD: 10.52,
    JPY: 1112,
    CNY: 57.6,
    PLN: 31.65,
    NOK: 80.41,
    KRW: 10097,
    AED: 29.35,
    IDR: 120478,
  },
  proton: {
    USD: 4.99,
    GBP: 3.83,
    EUR: 4.44,
    AUD: 7.34,
    CAD: 6.57,
    JPY: 695,
    CNY: 35.97,
    PLN: 19.76,
    NOK: 50.22,
    KRW: 6306,
    AED: 18.33,
    IDR: 120554,
  },
};

export const COMPARISON_TABLE_DATA = ({ currency, pricePerMonth, isLoading, t } = {}) => ({
  highlight: {
    col: 1,
    text: <ComparisonHighlightLabel text={t('common:actions.getAtlasVpn')} />,
  },
  content: [
    [
      { type: 'empty' },
      { type: 'image', image: atlasvpnLogo, alt: 'Atlas VPN' },
      { type: 'image', image: cyberGhostLogo, alt: 'CyberGhost' },
      { type: 'image', image: hotspotShieldLogo, alt: 'Hotspot Shield' },
      { type: 'image', image: protonVpnLogo, alt: 'Proton VPN' },
      { type: 'image', image: expressVpnLogo, alt: 'ExpressVPN' },
    ],
    [
      { type: 'label', label: t('common:comparisonFeatures.unlimitedDevices') },
      { type: 'feature', feature: true },
      { type: 'feature', feature: false },
      { type: 'feature', feature: false },
      { type: 'feature', feature: false },
      { type: 'feature', feature: false },
    ],
    [
      { type: 'label', label: t('common:comparisonFeatures.multihopServers') },
      { type: 'feature', feature: true },
      { type: 'feature', feature: false },
      { type: 'feature', feature: false },
      { type: 'feature', feature: true },
      { type: 'feature', feature: false },
    ],
    [
      { type: 'label', label: t('common:comparisonFeatures.support') },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
      { type: 'feature', feature: false },
      { type: 'feature', feature: true },
    ],
    [
      { type: 'label', label: t('common:comparisonFeatures.wireGuardProtocol') },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
      { type: 'feature', feature: false },
    ],
    [
      { type: 'label', label: t('common:comparisonFeatures.serverSpeed') },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
      { type: 'feature', feature: false },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
    ],
    [
      { type: 'label', label: t('common:comparisonFeatures.passwordlessLogin') },
      { type: 'feature', feature: true },
      { type: 'feature', feature: true },
      { type: 'feature', feature: false },
      { type: 'feature', feature: false },
      { type: 'feature', feature: false },
    ],
    [
      { type: 'label', label: t('common:comparisonFeatures.price') },
      {
        type: 'text',
        label:
          !isLoading &&
          pricePerMonth &&
          t('common:priceTexts.pricePerMonth', {
            currency,
            pricePerMonth,
          }),
      },
      {
        type: 'text',
        label:
          !isLoading &&
          t('common:priceTexts.pricePerMonth', {
            currency,
            pricePerMonth: vpnProviderPrices.cyberGhost[currency],
          }),
      },
      {
        type: 'text',
        label:
          !isLoading &&
          t('common:priceTexts.pricePerMonth', {
            currency,
            pricePerMonth: vpnProviderPrices.hotSpotShield[currency],
          }),
      },
      {
        type: 'text',
        label:
          !isLoading &&
          t('common:priceTexts.pricePerMonth', {
            currency,
            pricePerMonth: vpnProviderPrices.proton[currency],
          }),
      },
      {
        type: 'text',
        label:
          !isLoading &&
          t('common:priceTexts.pricePerMonth', {
            currency,
            pricePerMonth: vpnProviderPrices.expressVpn[currency],
          }),
      },
    ],
  ],
});

export const PLAN_COMPARISON = ({ t, currency, pricePerMonth, isLoading }) => ({
  free: {
    title: t('common:plans.free'),
    description: t('common:planComparisonCard.description.freePlan'),
    pricing:
      !isLoading &&
      t('plans:checkoutCard.regularPricePerMonth', {
        currency,
        regularPrice: '0.00',
      }),
    features: [
      {
        label: t('common:planComparisonCard.features.freeDataPerMonth', { dataAmount: '5GB' }),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.freeServers'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.militaryGradeEncryption'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.unlimitedSpeed'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.unlimitedDevices'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.emailOnlySupport'),
        type: 'nonFeature',
      },
      {
        label: t('common:planComparisonCard.features.fastestStreaming'),
        type: 'nonFeature',
      },
      {
        label: t('common:planComparisonCard.features.protocols'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.killSwitch'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.splitTunneling'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.noActivityLogs'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.tvSupport'),
        type: 'feature',
      },
    ],
    isPremium: false,
  },
  premium: {
    title: t('common:plans.premium'),
    description: t('common:planComparisonCard.description.premiumPlan'),
    pricing:
      !isLoading &&
      t('plans:checkoutCard.regularPricePerMonth', {
        currency,
        regularPrice: pricePerMonth,
      }),
    features: [
      {
        label: t('common:planComparisonCard.features.unlimitedData'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.servers', { serverCount: SERVER_COUNT }),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.militaryGradeEncryption'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.unlimitedSpeed'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.unlimitedDevices'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.liveChatSupport'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.fastestStreaming'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.protocols'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.killSwitch'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.splitTunneling'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.noActivityLogs'),
        type: 'feature',
      },
      {
        label: t('common:planComparisonCard.features.tvSupport'),
        type: 'feature',
      },
    ],
    isPremium: true,
  },
});
